import Vue from 'vue'

export const schema = {
	state: {
	  clientUserSchemaId: process.env.VUE_APP_APOLLO_SCHEMA_ID
  },
	mutations: {
		schemaSet(state, { property = '', value = null }) {
			if (property) Vue.set(state, property, value)
			else state.data = value
		},
	},
	actions: {
		schemaSet(context, payload) {
			const {commit} = context
			commit('schemaSet', payload)
		},
	},
	getters: {
    clientUserSchemaId: state => state.clientUserSchemaId
  },
}
