import store from '@/store/store'

const loginPlugin = {
	install(Vue, options) {
		Vue.specificBeforeEach = async ({ to }) => {
			if (!store.getters.world.cities.length) await store.dispatch('get_cities')
			if (!store.getters.app.tutorials.length) await store.dispatch('get_tutorials')
			
			// Récupération de la ville
			if (to.params.city_slug) {
				await store.dispatch('set_current_city', { slug: to.params.city_slug })
			} else
				if (store.getters.world.current.city !== null) store.commit('EDIT_WORLD_CURRENT', { city: null })
			
			// Récupération de la game en cours
			if (to.params.game_id) {
				await store.dispatch('launch_game', { game_id: to.params.game_id })
			} else
				if (store.getters.game.progression !== null) store.commit('EDIT_GAME_STATE', { progression: null })
		}
	}
}

export default loginPlugin
