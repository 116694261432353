import Dexie from "dexie"

export default {
	install(Vue, options) {
		const { dbName, version } = options
		// Dexie.delete(dbName)
		const db = new Dexie(dbName)
		db.version(version).stores({
			medias: 'id, slug, *tags'
		})
		const m = db.medias
		Vue.mDeleteKeys = function({ primaryKeys = [] }) {
			return m.bulkDelete(primaryKeys)
		}
		Vue.mDeleteKey = function({ primaryKey }) {
			return m.delete(primaryKey)
		}
		Vue.mAdd = function() {
			return m.add(data)
		}
		Vue.mBulkAdd = async function(data) {
			return await m.bulkAdd(data)
		}
		Vue.mWhere = function(conditions) {
			return m.where(conditions)
		}
		Vue.mGetLocalMedias = function() {
			return m.toArray()
		}
		Vue.mDeleteObsolete = async function({ foreignMedias = [] }) {
			const localMedias = await m.toArray()
			const oForeignMedias = foreignMedias.reduce((a, c) => Object.assign(a, { [c.id]: c }), {})
			localMedias
				.forEach(file => {
					const { id } = file
					if (!oForeignMedias[id]) {
						m.delete(id)
					}
				})
		}
		Vue.mUpdateKey = function({ key, changes }) {
			return m.update(key, changes)
		}
		Vue.mUpdate = async function({ changes = [] }) {
			if (!changes.length) return
			const localMedias = await Vue.mGetLocalMedias()
			if (!localMedias.length) {
				return await Vue.mBulkAdd(changes)
			} else {
				for (const x of changes) {
					await m.put(x)
				}
			}
		}
	}
}
