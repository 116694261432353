import Vue from 'vue'
import Apollo from '@/apollo/apollo-client'


// === STATE =====================================================================================
const state = {
	map_assets: [],
	cities: [],
	new_city_badges: [], // add city id to show passeport badge animation
	current: {
		city: null,
		// game: false
	}
}








// === GETTERS =====================================================================================
const getters = {
	world(state) { return state },
}







// === ACTIONS =====================================================================================
const actions = {

	// Récupère la liste des villes
	get_cities: async ({ commit, dispatch }, data) => {
		const result = await Apollo.query('CLIENT_SECTION_BY', { slug: 'province' })
		if (result && result[0] && result[0].children && result[0].clientMedias) {
			commit('EDIT_WORLD_STATE', { map_assets: result[0].clientMedias, cities: result[0].children })
		}
	},

	add_new_city_badge({ commit }, data) {
		commit('ADD_NEW_CITY_BADGE', { id: data.id })
	},

	remove_new_city_badge({ commit }, data) {
		commit('REMOVE_NEW_CITY_BADGE', { id: data.id })
	},

	set_current_city({ commit, state }, data) {
		if (data.slug) {
			let city = state.cities.find((c) => { return c.slug == data.slug })
			if (city) commit('EDIT_WORLD_CURRENT', { city })
		}
	},

}



// === MUTATIONS =====================================================================================
const mutations = {

	EDIT_WORLD_STATE(state, data) {
		for (var i in data) {
			Vue.set(state, i, data[i])
		}
	},

	EDIT_WORLD_CURRENT(state, data) {
		for (var i in data) {
			Vue.set(state.current, i, data[i])
		}
	},

	ADD_NEW_CITY_BADGE(state, data) {
		if (!state.new_city_badges.includes(data.id)) state.new_city_badges.push(data.id)
	},

	REMOVE_NEW_CITY_BADGE(state, data) {
		state.new_city_badges = state.new_city_badges.filter((el) => { return el != data.id })
	},

}

export default { state, getters, actions, mutations }
