import { _apollo } from '@/js/managers/_apollo'
import { createAnonymousUser } from '@/js/jwt/jwt'
import functions from '@/js/managers/functions'
import store from '@/store/store'

export const manageSignUp = function({ _params = {}, _func = {} }) {
	const params = Object.assign({}, { ..._params })
	const start = async function start() {
		let { getters: { user: { onboarding:status } = {} } = {} } = store
		if (!status) {
			await store.dispatch('get_user')
			let { getters: { user: { onboarding:status } = {} } = {} } = store
			if (!status) {
				const isUserCreated = await createAnonymousUser()
				if (!isUserCreated) return null
			}
		}
		await store.dispatch('get_user')
		const { getters: { user } = {} } = store
		return await onboarding({ user })
	}
	const onboarding = async function onboarding({ user = {} }) {
		const { onboarding } = user
		const { userStates } = params
		if (!onboarding || !userStates) return null
		const { getters: { manageSignUp: { func = {} } = {} } = {} } = store
		if (func[onboarding]) return await func[onboarding]()
		return null
	}
	const startProgression = onboarding => async function startProgression({ gameId }) {
		const { mutation, route } = params.userStates[onboarding]
		const variables = { gameId }
		const result = await _apollo({ mutation, variables })
		const { id:progressionId, currentMark: { step } = {} } = result
		store.commit('progressionId', { progressionId })
		if (!progressionId || !step) return route
		return { step }
	}
	const is_anonymous = async function is_anonymous() {
		let { createAnonymous: { query, variables } = {} } = params
		const { id:gameId } = (await _apollo({ query, variables }) || [])[0]
		return await startProgression('is_anonymous')({ gameId })
	}
	const is_confirmed = async function is_confirmed() {
		// let { createProfile: { query, variables } = {} } = params
		// const { id:gameId } = (await _apollo({ query, variables }) || [])[0]
		// return await startProgression('is_confirmed')({ gameId })
	}
	const is_validated = async function isvalidated() {
	}
	return {
		params,
		func: {
			...functions,
			..._func,
			start,
			onboarding,
			is_anonymous,
			is_confirmed,
			is_validated,
		}
	}
}
