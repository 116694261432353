<template>
	<div class="tuto" :class="city_slug">
		<div class="tuto-bg">
			<div class="dots"></div>
			<div class="line"></div>
			<div class="line"></div>
		</div>

		<div v-if="mask" class="mask">
			<div :style="mask_styles" class="hole" v-hammer:tap="tap_mask"></div>
		</div>
		<div v-if="!mask && overlay" class="overlay"></div>

		<div class="tuto-content" :class="{ 'no-interaction': current_step && current_step.mask_interaction }">

			<transition name="background-animation">
				<div v-if="background_animation" class="background-animation" ref="background_animation"></div>
			</transition>

			<transition name="hint">
				<div v-if="mobile_position" class="hint" :class="mobile_position" :key="'hint-' + current_route + '-' + current_step.id">
					<div class="text" v-html="current_step_text"></div>
				</div>
			</transition>

			<transition name="character">
				<div v-show="current_step && !mobile_position && current_step.text" class="character" key="character" :class="{ tall: is_tall_animation }">
					<transition name="bubble" mode="out-in">
						<div v-if="current_step && !loading && !mobile_position" class="bubble" :key="current_route + '-' + current_step.id">
							<div class="text" v-html="current_step_text"></div>
						</div>
					</transition>
					<div class="illustration" ref="character" :class="{ flip: flip_animation }"></div>
				</div>
			</transition>

			<transition name="answers" mode="out-in">
				<div v-if="current_step && current_step.answers" class="answers" :key="current_route + '-' + current_step.id">
					<div v-for="(answer, index) in current_step.answers" class="answer" :key="index" v-hammer:tap="() => select_answer(answer)">{{ answer.text }}</div>
				</div>
			</transition>

			<transition name="form" mode="out-in">
				<form v-if="current_step && current_step.form" class="form" :class="{ loading: form_loading }" @submit.prevent="submit_form">
					<div v-for="(line, index) in current_step.form" :key="index" class="line">
						<input :type="line.type" class="input" :name="line.name" :placeholder="line.placeholder" required>
					</div>
					<div class="line line-buttons">
						<div class="button red" v-hammer:tap="() => back_concours()">Retour</div>
						<button type="submit" class="button green">Confirmer</button>
					</div>
				</form>
			</transition>

			<div class="buttons">
				<div v-if="settings.skip_button" class="button skip" v-hammer:tap="() => $emit('skip')">Passer le tutoriel</div>
				<div v-if="settings.next_button && !loading" class="button next" v-hammer:tap="() => next()">
					<span v-if="is_last_step()">Terminer</span>
					<span v-else>Continuer</span>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getValueOnPath } from '@/js/utils/utils'
import {manageLottie} from "@/js/managers/manageLottie"

export default {
	name: 'tuto',
	data() {
		return {
			current_route: 'default',
			index: -1,
			loading: true,
			data_tuto: null,
			mask: null,
			background_animation: false,
			flip_animation: false,
			is_tall_animation: false,
			form_loading: false,
			anims: [],
			user_data: null
		}
	},
	props: {
		tuto: { default: null }
	},
	computed: {
		...mapGetters([ 'app', 'world' ]),
		city_slug() {
			return (getValueOnPath(this.world, 'current.city') || { slug: '' }).slug
		},
		mobile_position() {
			if (this.current_step && this.current_step.mobile && this.current_step.mobile.position) {
				return this.current_step.mobile.position
			}
			return false
		},
		current_step() {
			if (this.tuto && this.data_tuto && this.data_tuto.routes[this.current_route] && this.index > -1 && this.data_tuto.routes[this.current_route][this.index]) {
				return { ...this.data_tuto.routes[this.current_route][this.index], id: this.index }
			}
			return null
		},
		animations() {
			if (this.tuto.animations) return this.tuto.animations
			return this.data_tuto.animations
		},
		settings() {
			return {
				skip_button: true,
				next_button: true,
				...this.data_tuto.settings,
				...this.current_step && this.current_step.settings ? this.current_step.settings : {}
			}
		},
		overlay() {
			if (this.current_step && typeof this.current_step.overlay !== 'undefined') return this.current_step.overlay
			if (this.data_tuto && typeof this.data_tuto.overlay !== 'undefined') return this.data_tuto.overlay
			return true
		},
		mask_styles() {
			if (this.mask) {
				const border = 8
				return {
					left: (this.mask.x - border) + 'px',
					top: (this.mask.y - border) + 'px',
					width: (this.mask.width + border * 2) + 'px',
					height: (this.mask.height + border * 2) + 'px'
				}
			}
			return null
		},
		current_step_text() {
			let text = ""
			if (this.current_step && this.current_step.text) {
				text = this.current_step.text
				let matches = text.match(/\[\[([a-z]+)\]\]/gm)
				if (matches) {
					matches.forEach((match) => {
						const slug = match.replace('[[', '').replace(']]', '')
						text = text.replace(match, '<div class="lottie" data-slug="' + slug + '"></div>')
					})
				}
			}
			return text
		}
	},
	watch: {
		current_step_text() {
			setTimeout(() => {
				const anims = [ ...this.$el.querySelectorAll('.hint .text .lottie, .bubble .text .lottie') ]
				anims.forEach(($element) => {
					const slug = $element.getAttribute('data-slug')
					if (slug) {
						this.anims.push(
							manageLottie.create({
								container: $element,
								animationData: require('@/assets/img/tuto/' + this.current_step[slug]),
								renderer: 'svg',
								loop: true,
								autoplay: true
							})
						)
					}
				})
			}, 500)
		},
		current_step(newStep, oldStep) {

			if (oldStep) {
				if (oldStep.highlight) {
					oldStep.highlight.forEach((h) => {
						let el = document.querySelector(h)
						if (el) el.classList.remove('tuto-highlight')
					})
				}
				if (oldStep.mask) {
					this.mask = null
				}
			}

			if (newStep) {

				if (newStep.highlight) {
					newStep.highlight.forEach((h) => {
						let el = document.querySelector(h)
						if (el) el.classList.add('tuto-highlight')
					})
				}
				if (newStep.mask) {
					let el = document.querySelector(newStep.mask)
					if (el) this.mask = el.getBoundingClientRect()
				}

				if (newStep.animation) {
					this.set_animation(newStep.animation)
				}

				if (newStep.background_animation) {
					this.set_background_animation(newStep.background_animation)
				}

			}
		}
	},
	methods: {
		is_last_step() {
			const { current_route, data_tuto: { routes } = {} } = this
			if (current_route && routes) return this.index === (routes[current_route] || []).length - 1
			return false
		},
		select_answer(answer) {
			if (answer.route) this.set_route(answer.route)
			else if (answer.callback) this[answer.callback](answer)
			this.next()
		},

		set_route(route, index = -1) {
			this.current_route = route
			this.index = index
		},

		save_data(answer) {
			if (answer && answer.data && answer.data.key && answer.data.value) {
				if (!this.user_data) this.user_data = {}
				this.user_data[answer.data.key] = answer.data.value
			}
		},

		end_tuto() {
			this.$store.commit('EDIT_APP_STATE', { tuto_user_data: this.user_data })
			this.$emit('end')
		},

		async submit_form(e) {
			if (!this.form_loading) {
				this.form_loading = true
				const form = e.srcElement
				await fetch(this.current_step.google_sheet, {
					method: 'POST',
					body: new FormData(form)
				})
				this.$store.dispatch('update_tutorial', { tuto: this.tuto.name + '_submitted', status: 'done' })
				this.form_loading = false
				this.next()
			}
		},

		tap_mask() {
			const el = document.querySelector(this.current_step.mask)
			if (el && el.hammer && el.hammer.handlers && el.hammer.handlers.tap) {
				el.hammer.handlers.tap[0]()
			}
			this.next()
		},

		back_concours() {
			this.set_route('default', 2)
		},

		next() {
			if (this.data_tuto && this.data_tuto.routes[this.current_route]) {

				if (this.current_step && this.current_step.redirect) {
					this.$store.commit('EDIT_APP_STATE', { main_loader: true })
					this.$router.push({ name: this.current_step.redirect })
					this.index = -1
					this.end_tuto()
					return
				}

				const new_index = this.index + 1
				let new_step = this.data_tuto.routes[this.current_route][new_index]
				if (new_step) {
					if (this.app.device === 'mobile' && new_step.mobile_ignore) {
						this.index = new_index
						this.next()
						return
					}
					const delay = new_step.delay ? new_step.delay : 0
					if (delay > 0) {
						// this.index = -1
						this.loading = true
					}
					this.index = new_index
					setTimeout(() => {

						this.loading = false
						if (this.current_step.auto_next_after_delay) this.next()
					}, delay)
				} else {
					this.index = -1
					this.end_tuto()
				}
			}
		},

		set_animation(animation) {
			const anim = this.animations.find((m) => m.tags.includes(animation))
			if (anim && anim.data) {
				this.is_tall_animation = this.data_tuto.settings && this.data_tuto.settings.tall_animation ? true : anim.tags && anim.tags.includes('tall')
				if (anim.tags.includes('is_tall')) this.is_tall_animation = true
				this.$refs.character.innerHTML = ''
				this.$nextTick(() => {
					this.anims.push(
						manageLottie.create({
							container: this.$refs.character,
							animationData: JSON.parse(anim.data),
							renderer: 'svg',
							loop: true,
							autoplay: true,
							rendererSettings: { preserveAspectRatio: 'xMidYMax slice' }
						})
					)
					this.flip_animation = this.data_tuto.settings && this.data_tuto.settings.flip_animation ? true : false
				})
			}
		},

		set_background_animation(animation) {
			this.background_animation = animation !== null
			this.$nextTick(() => {
				this.$refs.background_animation.innerHTML = ''
				this.anims.push(
					manageLottie.create({
						container: this.$refs.background_animation,
						animationData: require('@/assets/img/bus_' + animation + '.json'),
						renderer: 'svg',
						loop: true,
						autoplay: true
					})
				)
			})

		}
	},
	mounted() {
		// const anim = this.data_tuto.routes[this.current_route][0].animation
		// this.set_animation(anim)

		if (this.data_tuto.highlight) {
			const el = document.querySelector(this.data_tuto.highlight)
			if (el) el.classList.add('tuto-highlight')
		}

		setTimeout(() => {
			this.next()
		}, 1000)
	},
	async created() {
		this.index = -1
		this.loading = true
		this.data_tuto = (this.app.tutorials.find(t => t.slug === this.tuto.name) || { info: false }).info
		// if (!this.data_tuto) this.data_tuto = require('@/data/tuto_' + this.tuto.name + '.json')
		this.loading = false
	},
	beforeDestroy() {
		const elements = [ ...document.querySelectorAll('.tuto-highlight') ]
		if (elements.length) {
			elements.forEach((el) => {
				el.classList.remove('tuto-highlight')
			})
		}
		this.anims.length = 0
	}
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/variables'

#app.mobile
	.tuto .tuto-content
		.character
			left 16px
			width (380px * 1)
			height (650px * 1.05)
			transform translate(-35%, 40%)
			&.tall
				height (750px * 1)
			.bubble
				width 320px
				left 35%
				font-size 1.7rem
				line-height 1.2em
		.hint
			width 320px
			left calc(50% - 160px)
			font-size 1.7rem
			line-height 1.2em
		.answers
			left auto
			right 24px
			bottom 120px
			width 220px
			.answer
				padding-left 16px
				padding-right 16px
				font-size 1.6rem
				line-height 1.2em
		.buttons
			left 16px
			right 16px
			bottom 24px
			.button
				height 48px
				padding 0 20px
				line-height 48px
				font-size 1.4rem
				margin 0
				&.next
					padding-right 40px

.tuto
	absolute 0
	&.tuto-enter-active, &.tuto-leave-active
		transition 0.5s easeOutQuart
		.mask, .overlay
			transition 0.5s easeOutQuart
		.tuto-bg
			.line
				&:nth-child(1)
					transition 0.4s easeOutQuart
					transition-delay 0.1s
					animation none
				&:nth-child(2)
					transition 0.3s easeOutQuart
					transition-delay 0.2s
					animation none
		.tuto-content
			.buttons
				transition-delay 0.75s
	&.tuto-enter
		.tuto-bg
			opacity 0
			transform translateX(-48px)
			.line
				transform translateX(-48px)
				opacity 0
		.tuto-content
			.buttons
				opacity 0
				transform translateY(50%)
	&.tuto-leave-to
		.tuto-bg, .tuto-content, .overlay, .mask
			opacity 0

	.tuto-bg
		absolute -20%
		left 30%
		right -150px
		background linear-gradient(30deg, alpha(#3DC0F0, 90%), alpha(#355FA9, 90%))
		// background-color alpha(#477573, 95%)
		// transform skewX(-16deg)
		transform rotate(10deg)
		transition 0.5s easeOutQuart
		// z-index 1
		.dots
			absolute 0
			overflow hidden
			&:before
				content ''
				absolute -50px
				background-image url("data:image/svg+xml,%3Csvg viewBox='0 0 50 50' width='50px' height='50px' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3C/defs%3E%3Ccircle style='fill:rgb(83, 149, 200);' cx='25' cy='25' r='18' %3E%3C/circle%3E%3C/svg%3E")
				perspective 1000px
				opacity 0.5
				animation bg-scrolling 5s linear infinite
		.line
			position absolute
			top 0
			bottom 0
			perspective 1000px
			&:nth-child(2)
				left -16px
				width 12px
				animation bg-line (1s * 10) easeInOutSine infinite
				background-color #67D1EC
			&:nth-child(3)
				left -56px
				width 20px
				animation bg-line (1.5s * 10) easeInOutSine infinite
				animation-delay 0.25s
				background-color #4D72AE
	.overlay
		absolute 0
		z-index 3
		background-color alpha(#676767, 60%)
	.mask
		absolute 0
		z-index 3
		.hole
			position absolute
			box-shadow 0 0 0 9999px alpha(#676767, 60%)
			border-radius 8px
			transition 0.5s easeOutQuart
			perspective 1000px
			cursor pointer

	.tuto-content
		absolute 0
		z-index 3
		&.no-interaction
			pointer-events none
		.background-animation
			position absolute
			left 30%
			right 0
			top 50%
			height 100%
			width 1400px
			transform translate(0, -50%)
			&.background-animation-enter-active
				transition transform 3s easeOutSine
			&.background-animation-enter
				transform translate(100%, -50%)
		.character
			position absolute
			left 10%
			bottom 0
			width (380px * 1.3)
			height (650px * 1.35)
			transition 0.25s easeOutQuart
			transform translate(0, 27%)
			font-size 2rem
			line-height 1.2em
			&.tall
				height (750px * 1.35)
				.bubble
					bottom calc(100% - 32px)
			&.character-enter, &.character-leave-to
				transform translate(-24px, 27%)
				opacity 0

			.bubble
				position absolute
				bottom calc(100% - 16px)
				left 40%
				width 80%
				max-height 242px
				background-color #fff
				border-radius 12px
				padding 24px
				transform-origin top left
				box-shadow -3px 3px alpha(#000, 50%)
				user-select none
				cursor default
				// animation bubble 4s easeOutBack infinite
				// transform-origin 30%
				&:before
					content ''
					position absolute
					left 0
					bottom -32px
					height 64px
					width 100%
					background url(../assets/img/bubble-bottom.svg) bottom left 40% no-repeat
					background-size contain
				&.bubble-enter-active, &.bubble-leave-active
					transition 0.35s easeOutBack
				&.bubble-enter
					transform rotate(4deg) translate3D(-8px, 8px, 0)
					opacity 0
				&.bubble-leave-to
					transform rotate(-4deg) translate3D(8px, -8px, 0)
					opacity 0
				.text
					height 100%
					text-align center
					color dark
					>>> strong
						color #1CB5E0
						font-weight 700
					>>> img
						display block
						margin 0 auto
						max-height 100px
						width auto
						max-width 100%
					>>> .lottie
						// margin 8px 0
						height 50px
						width 100%
			.illustration
				width 100%
				height 100%
				// background-color alpha(red, 50%)
				transform-origin center center
				transform scaleX(-1)
				&.flip
					transform none
		.hint
			position absolute
			left calc(50% - 200px)
			width 100%
			max-width 400px
			font-size 2rem
			line-height 1.2em
			background-color #fff
			border-radius 12px
			padding 24px
			transform-origin top left
			// border 3px solid #ccc
			box-shadow -3px 3px alpha(#000, 50%)
			user-select none
			cursor default
			&.top
				top 96px
			&.middle
				top 50%
				transform translateY(-50%)
			&.bottom
				bottom 96px
			&.hint-enter-active, &.hint-leave-active
				transition 0.35s easeOutBack
			&.hint-enter
				transform translate3D(0, 8px, 0)
				opacity 0
			&.hint-leave-to
				transform translate3D(0, -8px, 0)
				opacity 0
			.text
				height 100%
				text-align center
				color dark
				>>> strong
					color #1CB5E0
					font-weight 700
				>>> img
					height 100px
					width auto
					max-width 100%
				>>> .lottie
					// margin 8px 0
					height 50px
					width 100%
					background-color red
		.answers
			position absolute
			left calc(10% + 400px)
			bottom 250px
			width 300px
			transition 0.35s easeOutQuart
			&.answers-enter-active
				transition-delay 0.35s
			&.answers-enter
				transform translateY(24px)
				opacity 0
			&.answers-leave-to
				transform translateY(-24px)
				opacity 0
			.answer
				width 100%
				min-height 48px
				padding 12px 24px
				margin 0 0 16px 0
				flex center center
				text-align center
				line-height 1.1em
				box-shadow -2px 2px alpha(#000, 25%)
				color #fff
				border-radius 64px
				font-weight 700
				font-size 2rem
				cursor pointer
				transition 0.1s easeOutQuart
				&:last-child
					margin 0
				&:nth-child(1)
					background-color darken(#5ED174, 25%)
				&:nth-child(2)
					background-color darken(#2F7BB5, 5%)
				&:hover
					transform translateY(-2px)
					box-shadow -2px 4px alpha(#000, 25%)
				&:active
					box-shadow none
					transform translateY(2px)

		.form
			position absolute
			left calc(50% - 150px)
			top 60%
			width 300px
			transition 0.25s easeOutQuart
			&.form-enter
				transform translateY(24px)
				opacity 0
			&.form-leave-to
				opacity 0
			&.loading
				&:after
					content ''
					absolute 0
					cursor wait
				.line
					.button.green
						&:after
							content ''
							absolute 0
							border-radius 40px
							background green url(../assets/img/button-loader.svg) center center no-repeat
							background-size 32px
							cursor wait
			.line
				margin 0 0 16px 0
				text-align center
				&:last-child
					margin 0
				&.line-buttons
					flex center center
				.input
					width 100%
					height 48px
					padding 0 24px
					background #fff
					box-shadow 0 2px 4px alpha(#000, 50%)
					outline none
					border-radius 40px
					border none
					font inherit
					text-align left
					color dark
				.button
					flex center center
					display inline-flex
					height 48px
					padding 0 24px
					margin 0 8px
					background-color green
					box-shadow 0 2px 4px alpha(#000, 50%)
					border none
					border-radius 40px
					font inherit
					color #fff
					text-transform uppercase
					font-weight 500
					cursor pointer
					user-select none
					&:hover
						background-color darken(green, 5%)
					&:active
						background-color darken(green, 10%)
						transform translateY(1px)
					&.red
						background-color #D85E53
						&:hover
							background-color darken(#D85E53, 5%)
						&:active
							background-color darken(#D85E53, 10%)


		.buttons
			position absolute
			left 64px
			right 64px
			bottom 64px
			transition 0.25s easeOutBack
			.button
				height 56px
				min-width 48px
				padding 0 24px
				margin 0 8px
				background-color red
				box-shadow inset 0 0 0 4px #fff
				border-radius 48px
				text-transform uppercase
				font-size 2rem
				line-height @height
				text-align center
				color #fff
				cursor pointer
				&:hover
					transform translateY(-1px)
				&:active
					transform translateY(2px)
				&.skip
					float left
					margin 0 16px 0 0
				&.next
					float right
					margin 0 0 0 16px
					padding-right 48px
					background url(../assets/img/chevron-right.svg) right 8px center no-repeat
					background-size auto 70%
					background-color #01053B

@media (min-height: 700px)
	#app.mobile .tuto .tuto-content
		.buttons
			bottom 40px

@keyframes bg-scrolling
	0%
		transform translate3D(-50px, 50px, 0)

@keyframes bg-line
	0%, 100%
		transform translateX(0)
	50%
		transform translateX(-100%) scale(1.5)

</style>
