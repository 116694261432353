import Apollo from '@/apollo/apollo-client'
import { purify } from '@/js/utils/utils'

export const _apollo = async function({ mutation, query, variables, onError }) {
	if (mutation) return await _mutation({ mutation, variables, onError })
	if (query) return await _query({ query, variables, onError })
	return false
}

const _query = async function({ query, variables, onError }) {
	return purify(await Apollo.query(query, variables, onError))
}

const _mutation = async function({ mutation, variables, onError }) {
	return purify(await Apollo.mutate(mutation, variables, onError ))
}
