<template>
	<div class="notification" :class="notification.status" @click="remove_notification()">
		{{ notification.message }}
	</div>
</template>

<script>

export default {
	name: 'Notification',
	data () {
		return {

		}
	},
	props: [ 'notification' ],
	methods: {
		remove_notification() {
			this.$store.dispatch('remove_notification', { notification: this.notification })
		}
	}
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'

.notifications-enter, .notifications-leave-to
	opacity 0
	transform translateX(100%)
.notifications-leave-active
	position absolute
	
.notification
	display block
	margin 0 0 8px 0
	background #999
	width 300px
	padding 24px
	border-radius radius
	cursor pointer
	font-size 1.4rem
	line-height 1.3
	color #fff
	text-align center
	transition 0.25s easeOutQuart
	// z-index 5001
	&.error
		background-color #f44336
	&.success
		background-color #689F38
	.icon
		display none
		position absolute
		left 20px
		top calc(50% - 16px)

</style>
