import { _apollo } from '@/js/managers/_apollo'
import store from '@/store/store'
import functions from '@/js/managers/functions'
import { refreshClientUserToken } from '@/js/jwt/jwt'

export const manageUser = function({ _params = {}, _func = {} }) {
	const params = Object.assign({}, { ..._params })
	const isUserValid = async function isUserValid() {
		return await refreshClientUserToken
			.call(store, {})
	}
	const getUser = async function getUser() {
		const { queries: { currentUser:query } = {} } = params
		return await _apollo({ query })
	}
	return {
		params,
		func: {
			...functions,
			..._func,
			isUserValid,
			getUser
		}
	}
}
