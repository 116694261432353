import { _apollo } from '@/js/managers/_apollo'
import store from "@/store/store"

export const manageTuto = function({ _params }) {
	const params = Object.assign({}, { ..._params })
	const getTuto = x => store.getters.app.tuto
	const setTuto = async x => await store.dispatch('set_tuto', x)
	const getTutoGame = async function getTutoGame({ to: { name } = {} }) {
		const { getters: { manageTuto: { func = {}, } = {} } = {} } = store
		const { redirect: { tuto } = {} } = params
		if (tuto[name]) {
			const tutoLocal = getTuto(tuto[name])
			if (!tutoLocal) await setTuto(tuto[name])
		}
	}
	return {
		func: {
			getTutoGame,
			getTuto,
			setTuto,
		},
	}
}
