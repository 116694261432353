import Vue from 'vue'
import { REFRESH_TOKEN } from '@/js/factory/refreshToken'

const state = {}
const getters = {
	accessToken: state => state.accessToken
}
const actions = {
	removeAccessToken({ commit }) {
		commit('removeAccessToken')
	},
	loginSuccessful({ commit }, { accessToken, refreshToken }) {
		if (!accessToken || !refreshToken) return
		REFRESH_TOKEN.setData({ data: refreshToken })
		commit('loginSuccessful', { accessToken })
	},
	loginWithRefreshTokenSuccessful({ commit }, { accessToken }) {
		if (!accessToken) return
		commit('loginSuccessful', { accessToken })
	}
}
const mutations = {
	loginSuccessful(state, { accessToken }) {
		Vue.set(state, 'accessToken', accessToken )
	},
	removeAccessToken(state) {
		REFRESH_TOKEN.clearData()
		Vue.set(state, 'accessToken', null)
	}
}

export default { state, getters, actions, mutations }
