import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import app from './modules/app'
import user from './modules/user'
import world from './modules/world'
import game from './modules/game'
import login from './modules/login.module'
import { schema } from './modules/schema.module'

const state = {

}

const store = new Vuex.Store({
	state,
	modules: {
		app,
		user,
		world,
		game,
    schema,
		login,
	},
	strict: process.env.NODE_ENV !== 'production'
})

export default store
