import Vue from 'vue'
import Router from 'vue-router'
import loginPlugin from "@/js/plugins/login-plugin"
import { manageRoute } from '@/js/managers/manageRoute'
import {garbageCollector} from "@/js/utils/garbageCollector"

Vue.use(loginPlugin)
Vue.use(Router)

const router = new Router({
  mode: "history",
	base: process.env.BASE_URL,
	routes: [
		{
			path: '/',
			name: 'world',
			meta: {
				secure: true,
			},
			component: () => import('@/views/view-main.vue'),
			children: [
				{
					path: 'sacados',
					name: 'backpack',
					meta: {
						secure: true,
					},
				},
				{
					path: 'sauvegarde',
					name: 'backpack-save',
					meta: {
						secure: true,
					},
				},
				{
					path: 'ville/:city_slug',
					name: 'city',
					meta: {
						secure: true,
					},
					children: [
						{
							path: 'jeu/:game_id',
							name: 'game',
							meta: {
								secure: true,
							},
							children: [
								{
									path: 'recompense',
									name: 'game_reward',
									meta: {
										secure: true,
									},
								}
							]
						}
					]
				},
			]
		},
		{
			path: '/depart',
			name: 'start',
			meta: {
				secure: false,
			},
			component: () => import('@/views/view-start.vue'),
			children: [
				{
					path: 'connexion',
					name: 'login',
					meta: {
						secure: false,
					},
					children: [
						{
							path: 'oublie',
							name: 'forgot',
							meta: {
								secure: false,
							},
							children: [
								{
									path: 'oublie',
									name: 'forgot-sent',
									meta: {
										secure: false,
									},
								}
							]
						}
					]
				},
				{
					path: 'compte',
					name: 'signup',
					meta: {
						secure: false,
					},
					children: [
						{
							path: 'envoye',
							name: 'signup-sent',
							meta: {
								secure: false,
							},
						}
					]
				},
				{
					path: 'jouer',
					name: 'play',
					meta: {
						secure: false,
					},
				}
			]
		},
	],
})

const _route = { ...manageRoute({
		params: {
			start: 'start',
			world: 'world',
			getUser: 'get_user',
			isAnonymous: 'is_anonymous',
			specificBeforeEach: Vue.specificBeforeEach,
		}
}) }
const { beforeEach } = _route

router.beforeEach(async (to, from, next) => {
	setTimeout(() => {
		garbageCollector.destroy()
	}, 1000)
	await beforeEach(to, from, next)
})

export default router
