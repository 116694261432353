import Vue from 'vue'
import Apollo from '@/apollo/apollo-client'
import { getValueOnPath } from '@/js/utils/utils'
import { sampleSize } from 'lodash'



// === STATE =====================================================================================
const state = {
	progression: null,
	step: null,
	loot_rewards: null,
	player_score: 0,
	opponent_score: 0,
	ui: {
		matching_error: null,
		zoomed_slide: null
	}
}








// === GETTERS =====================================================================================
const getters = {
	game(state) { return state }
}







// === ACTIONS =====================================================================================
const actions = {

	// Lance une partie et récupère l'étape en cours
	launch_game: async ({ commit }, data) => {
		const result = await Apollo.mutate('FIND_OR_CREATE_PROGRESSION', { gameId: data.game_id })
		if (result && result.currentMark && result.currentMark.step) {
			commit('EDIT_GAME_STATE', { progression: result, step: result.currentMark.step, loot_rewards: null, player_score: 0, opponent_score: 0 })
		}
	},



	// Termine une étape puis passe à la suivante
	complete_current_step: async ({ commit, state, dispatch }) => {
		const result = await Apollo.mutate('COMPLETE_STEP', { progressionId: state.progression.id })
		if (result && result.progression && result.nextStep && result.nextStep.content) {
			commit('EDIT_GAME_STATE', { progression: result.progression, step: result.nextStep.content, loot_rewards: (result.lootRewards || null) })
			if (result.nextStep.content.layout == 'result') dispatch('get_user')
		}
	},



	// Répond à une question puis passe à l'étape suivante
	trivia_send_answer: async ({ state, dispatch }, data) => {
		return await Apollo.mutate('SEND_ANSWER', {
			progressionId: state.progression.id,
			questionId: data.question_id,
			answers: data.answers_id
		})
	},



	draw_matching_elements: async ({ commit }) => {
		let sets = getValueOnPath(state, 'step.sets')

		// On passe les sets terminés et visibles comme non visibles
		sets.forEach((set) => {
			let matchable_elements = getValueOnPath(set, 'matchableElements')
			if (matchable_elements.filter((matchable_element) => { return matchable_element.checked }).length === matchable_elements.length) {
				matchable_elements.forEach((matchable_element) => {
					if (matchable_element.visible) commit('EDIT_MATCHABLE_ELEMENT', { matchable_element: matchable_element, visible: false })
				})
			}
		})

		// On récupère les sets non terminés
		let undone_sets = sets.filter((set) => {
			let matchable_elements = getValueOnPath(set, 'matchableElements')
			return matchable_elements.filter((matchable_element) => { return !matchable_element.checked }).length === matchable_elements.length
		})

		// On tire au hasard les sets à faire et on les rend visible
		let visible_sets = sampleSize(undone_sets, 2)
		visible_sets.forEach((set) => {
			let matchable_elements = getValueOnPath(set, 'matchableElements')
			matchable_elements.forEach((matchable_element) => {
				commit('EDIT_MATCHABLE_ELEMENT', { matchable_element: matchable_element, checked: false, active: false, visible: true })
			})
		})
	},


	toggle_active_matchable_element: async ({ commit }, data) => {
		if (data.matchable_element && !data.matchable_element.checked) {
			commit('EDIT_MATCHABLE_ELEMENT', { matchable_element: data.matchable_element, active: !data.matchable_element.active })
		}
	},



	matching_send_match: async ({ state, commit }, data) => {
		if (data.matchable_elements) {

			let matchable_element_ids = data.matchable_elements.map((m) => { return m.id })

			// Récupération du set avec toutes les bonnes réponses (sinon erreur)
			let sets = getValueOnPath(state, 'step.sets')
			let valid_set = sets.find((set) => {
				let elements = getValueOnPath(set, 'matchableElements')
				let nb_valid = elements.filter((matchable_element) => { return matchable_element_ids.includes(matchable_element.id) }).length
				if (nb_valid == elements.length) return true
				return false
			})

			// Modification de l'objet pour affichage
			data.matchable_elements.forEach((matchable_element) => {
				if (valid_set) {
					commit('EDIT_MATCHABLE_ELEMENT', { matchable_element, checked: true, active: false })
				} else {
					commit('EDIT_MATCHABLE_ELEMENT', { matchable_element, checked: false, error: Date.now(), active: false })
					commit('EDIT_GAME_UI_STATE', { matching_error: Date.now() })
				}
			})

			// Appel Apollo pour sauvegarder les données
			Apollo.mutate('SEND_MATCH', {
				progressionId: state.progression.id,
				matchableElementIds: matchable_element_ids
			})
		}
	},


	clear_game({ commit }, data) {
		commit('EDIT_GAME_STATE', { progression: false, player_score: 0, opponent_score: 0 })
	},

	edit_game_ui({ commit }, data) {
		commit('EDIT_GAME_UI_STATE', data)
	}


}



// === MUTATIONS =====================================================================================
const mutations = {

	EDIT_GAME_STATE(state, data) {
		for (var i in data) {
			Vue.set(state, i, data[i])
		}
	},

	EDIT_GAME_CURRENT(state, data) {
		for (var i in data) {
			Vue.set(state.current, i, data[i])
		}
	},

	EDIT_GAME_UI_STATE(state, data) {
		for (var i in data) {
			Vue.set(state.ui, i, data[i])
		}
	},

	EDIT_MATCHABLE_ELEMENT(state, data) {
		for (var i in data) {
			if (i != 'matchable_element') Vue.set(data.matchable_element, i, data[i])
		}
	},


}

export default { state, getters, actions, mutations }
