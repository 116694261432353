import Hammer from 'hammerjs'

const TouchPrevent = {}

TouchPrevent.install = function (Vue, options) {

	Vue.directive('touch-prevent', {

		inserted (el, binding, vnode, oldVnode) {
			let mc = new Hammer.Manager(el)
			mc.add( new Hammer.Tap() )
			mc.add( new Hammer.Pinch() )
			mc.on("pinch", (e) => {
				
			})
			mc.on("tap", (e) => {
				
			})
		}

	})

}

export default TouchPrevent