import functions from '@/js/managers/functions'
import store from '@/store/store'
import {createAnonymousUser} from "@/js/jwt/jwt"

export const manageRoute = function({ params }) {
	const {
		start,
		world,
		isAnonymous,
		getUser = null,
		specificBeforeEach = () => {}
	} = params
	const beforeEach = async function beforeEach(to, from, next) {
		const { params: { game_id } } = to
		const { name:nameFrom } = from
		if (game_id && !nameFrom) return next({ name: world })
		const { meta: { secure = true } = {} } = to
		if (to.name === start) return next()
		if (!secure) return next()
		let {
			getters: {
				manageUser: { func: { isUserValid } = {}, } = {},
				manageSignUp: { params: { redirect: { onboarding:redirectOnBoarding } = {}, } = {}, } = {},
				manageTuto: { func: { getTutoGame } = {}, } = {},
				accessToken = null,
			} = {},
			user: { onboarding } = {},
		} = store || {}
		if (accessToken) {
			await specificBeforeEach({ to })
			if (to.name === start) return next()
			if (onboarding === isAnonymous) return next({ name: start })
			if (!from.name) return next({ name: start })
			if (to.name === world) return next()
		}
		if (onboarding) {
			const name = redirectOnBoarding[onboarding]
			if (name) {
				if (name === to.name) return next()
				return next({ name })
			}
		}

		if (!accessToken) {
			const validUser = await isUserValid()
			if (!validUser) await createAnonymousUser()
			await store.dispatch( getUser )
			if (validUser) {
				await specificBeforeEach({ to })
				if (to.name !== world) return next({ name: world })
				return next()
			}
		}
		if (!from.name) return next({ name: start })
		await getTutoGame({ to })
		next()
	}
	return {
		beforeEach,
		params: {},
		...functions,
	}
}
