import { extend } from 'vee-validate'
import { alpha_num, alpha_spaces, required, email, confirmed } from 'vee-validate/dist/rules'
import Vue from 'vue'

export const extendVeeValidate = () => {
	extend('required', {
		...required,
		message: (fieldName) => `Ce champ est requis.`
	})

	extend('confirmed', {
		...confirmed,
		message: (fieldName) => 'Le mot de passe et la confirmation du mot de passe ne correspondent pas.'
	})

	extend('alpha_num', {
		...alpha_num,
		message: (fieldName) => `Ce champ ne doit contenir que des lettres et des espaces.`
	})

	extend('alpha_spaces', {
		...alpha_spaces,
		message: (fieldName) => `Ce champ ne doit contenir que des lettres et des espaces.`
	})

	extend('email', {
		...email,
		message: (fieldName) => `Le courriel n’est pas valide.`
	})

	extend('min', {
		validate: (value, { min }) => value.length >= parseInt(min),
		params: ['min'],
		message: (fieldName, { min }) => `Le nombre minimal de caractères acceptés est ${min}.`
	})

	extend('max', {
		validate: (value, { max }) => value.length <= parseInt(max),
		params: ['max'],
		message: (fieldName, { max }) => `Le nombre maximal de caractères acceptés est ${max}.`
	})

	extend('strongPassword', {
		validate: (value) => new RegExp("^(?=.*[a-zA-Z])(?=.*[0-9])(?=.{8,})").test(value),
		// validate: (value) => new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})").test(value),
		message: (fieldName) => `Le mot de passe n’est pas fort.`
	})
}
