const noLocalStorage = (e) => {
	alert('You must allow cookies in your browser settings to be able to play this game.')
}

const storage = window.localStorage

export const factory = definition => ({
	definition,
	getDefinition() {
		return this.definition
	},
	getData() {
		try {
			return storage.getItem(this.getDefinition())
		} catch(e) {
			noLocalStorage(e)
		}
	},
	setData({data}) {
		try {
			storage.setItem(this.getDefinition(), data)
		} catch(e) {
			noLocalStorage(e)
		}
	},
	clearData() {
		try {
			storage.removeItem(this.getDefinition())
		} catch(e) {
			noLocalStorage(e)
		}
	},
	isData() {
		return !!this.getData()
	}
})
