export const garbageCollector = {
	collectors: [],
	add(func) {
		if (this.collectors.length >= 10) return
		this.collectors.push(func)
	},
	destroy() {
		for (let collector of this.collectors) {
			collector.destroy()
		}
	}
}
