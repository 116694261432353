export const manageEventListeners = () => ({
	listeners: [],
	add(element, event, func) {
		this.listeners.push({ element, event, func })
		element.addEventListener(event, func)
	},
	destroy() {
		for (let listener of this.listeners) {
			const { element, event, func } = listener
			element.removeEventListener(event, func)
		}
		this.listeners.length = 0
	}
})
